import $ from "jquery";
import {active} from "./const.js";

// ハンバーガーメニュー
$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $('#js-nav').slideToggle();
  if($('#js-searchButton').hasClass(active)) {
    $('#js-searchButton').removeClass(active);
    $('#js-gcse').slideUp();
  }
  return false;
});

// 検索メニュー
$('#js-searchButton').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $('#js-gcse').slideToggle();
  if($('#js-hamburger').hasClass(active)) {
    $('#js-hamburger').removeClass(active);
    $('#js-nav').slideUp();
  }
  return false;
});
