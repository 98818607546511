import $ from "jquery";
import {active} from "./const.js";

$('.js-searchHeading').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $(event.currentTarget).next().slideToggle();
});

// ソート条件が変更されたら検索用のクエリパラメータ−を作成
$('#js-sort').change(function() {
  resultChange();
});

// 検索ボタンが押されたら検索用のクエリパラメータ−を作成
$('#js-searchSubmit').on('click', (event) => {
  resultChange();
});

function resultChange() {
  // ソート
  const sortValue = $('#js-sort').val();

  // カテゴリーから探す
  const category = $('[name=c]:checked').map(function() {
    return $(this).val();
  }).get();
  const categoryValue = category.join('_');

  // タグから探す
  const tag = $('[name=t]:checked').map(function() {
    return $(this).val();
  }).get();
  const tagValue = tag.join('_');

  // エリアから探す
  const area = $('[name=a]:checked').map(function() {
    return $(this).val();
  }).get();
  const areaValue = area.join('_');

  // クエリパラメーターを作成
  if(sortValue || categoryValue || tagValue || areaValue) {
    let queryString = "";

    if(sortValue) {
      queryString += "sort=" + sortValue;
    }

    if(categoryValue) {
      if(queryString !== "") {
        queryString += "&";
      }
      queryString += "c=" + categoryValue;
    }

    if(tagValue) {
      if(queryString !== "") {
        queryString += "&";
      }
      queryString += "t=" + tagValue;
    }

    if(areaValue) {
      if(queryString !== "") {
        queryString += "&";
      }
      queryString += "a=" + areaValue;
    }

    window.location.href = '/all?' + queryString + '#result';
    return;
  }

  window.location.href = '/all';
}
